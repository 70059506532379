<template>
	<div>
		<div v-show="data">
			<div v-if="showText === undefined" class="cimgWrap">
				<img :class="imgClass" class="cimg" :src="data" alt="업로드한 이미지" />
				<b-button class="absolute top-0 right-0" @click="clear" variant="no">
					<b-icon font-scale="1" icon="x" />
				</b-button>
			</div>
			<div v-else>
				<p class="inline-block">{{ name }}</p>
				<b-button class="inline-block -top-0.5 p-1" @click="clear" variant="no">
					<b-icon font-scale="1" icon="x" />
				</b-button>
			</div>
			<p v-if="noInfo === undefined">업로드 이미지: {{ comma(w) }} x {{ comma(h) }}, {{ comma(size) }}MB</p>
		</div>
		<div v-show="!data">
			<b-form-file
				v-show="btnSelector === undefined"
				ref="fileInput"
				:class="btnClass"
				placeholder="이미지를 선택해주세요."
				@input="changeImg"
				accept="image/*"
				browse-text="이미지 선택"
			/>
			<div v-if="btnSelector !== undefined" :class="btnClass" class="cimgBtn" @click="tag.click()">
				<b-icon class="w-1/1 h-1/1 text-center verticalCenter" icon="plus" />
			</div>
		</div>
	</div>
</template>
<script>
import { uploadFile } from 'libs/axios'

export default {
	props: {
		cName: { default: 'cimg' },
		type: { default: '' },
		etcData: { default: '' },
		imgClass: { default: '' },
		btnClass: { default: '' },
		maxW: { default: 0 },
		maxH: { default: 0 },
		maxSize: { default: 3 },
		isEdit: false,

		btnSelector: {},
		showText: {},
		noInfo: {},
		directUpload: {},
		fitImg: {},
		silent: {},
	},
	data() {
		return {
			data: '',
			file: '',
			name: '',
			w: 0,
			h: 0,
			size: 0,

			e: [],
		}
	},

	methods: {
		setImg() {},
		changeImg() {
			const target = this.tag
			if (target.files.length) {
				this.e = []

				const file = target.files[0],
					reader = new FileReader()

				this.file = file
				this.name = file.name
				reader.onload = e => {
					this.data = e.target.result

					const img = new Image()
					img.onload = e => {
						this.w = e.target.width
						this.h = e.target.height

						if (this.fitImg !== undefined) {
							if (this.w != this.maxW || this.h != this.maxH)
								this.e.push(`이미지는 ${this.maxW} x ${this.maxH} 규격이어야 합니다.`)
						} else {
							if (this.maxW && this.w > this.maxW) this.e.push('이미지의 폭이 제한 범위를 초과했습니다.')
							if (this.maxH && this.h > this.maxH)
								this.e.push('이미지의 높이가 제한 범위를 초과했습니다.')
						}

						if (this.silent === undefined && this.e.length) {
							alert.w(this.e)
							this.clear()
						}
					}
					img.src = reader.result
				}

				this.size = Math.ceil(file.size / 1024 / 1024)
				if (this.size > this.maxSize)
					this.e.push(`이미지파일 크기는 ${comma(this.maxSize)}MB 보다 작아야 합니다.`)
				reader.readAsDataURL(file)
				this.isEdit = true
			}
		},
		clear() {
			this.comp.reset()
			this.tag.value = ''
			this.data = ''
			this.file = ''
		},

		upload(addS3Url = true) {
			return new Promise(resolve => {
				if (!this.type) this.e.push('type이 입력되지 않았습니다')

				if (this.e.length) {
					if (this.silent === undefined) alert.w(this.e)
					throw this.e
				} else
					return uploadFile(this.file, this.type, undefined, undefined, this.etcData ?? {}).then(url => {
						url = url.substr(url.indexOf('/'))
						resolve(`${addS3Url ? s3Url : ''}${url}`)
					})
			})
		},
	},

	computed: {
		comp() {
			return this.$refs.fileInput
		},
		tag() {
			return this.comp.$refs.input
		},
	},
}
</script>

<style lang="scss" scoped>
.cimg {
	max-width: 100%;
}

.cimgBtn {
	cursor: pointer;
	border: 2px dashed var(--gray);
	border-radius: 10px;
}
</style>
