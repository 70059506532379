var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('bCard',[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"w-1/10"},[_vm._v("앱푸시 종류")]),_c('div',[_c('div',{staticClass:"flex mb-2"},_vm._l((_vm.typeOpts),function(v){return _c('b-form-radio',{staticClass:"mr-6",attrs:{"name":"type","value":v.val},on:{"change":_vm.changeType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_vm._v(" "+_vm._s(v.title)+" ")])}),1),_vm._l((_vm.typeOpts),function(v){return _c('p',{staticClass:"text-info"},[_vm._v(_vm._s(v.title)+" : "+_vm._s(v.exp))])})],2)]),_vm._l(([
				{ title: '템플릿 이름', key: 'name' },
				{ title: '제목', key: 'title' } ]),function(v){return _c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v(_vm._s(v.title))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ths[v.key]),expression:"ths[v.key]"}],staticClass:"form-control w-2/3 -mt-1.5",attrs:{"placeholder":v.title + ' 입력',"type":"text"},domProps:{"value":(_vm.ths[v.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ths, v.key, $event.target.value)}}})])}),_c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v("내용")]),_c('b-form-textarea',{staticClass:"w-2/3 resize-none",attrs:{"placeholder":"내용 입력","rows":"3"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v("이미지(선택)")]),_c('div',{staticClass:"w-2/3"},[_c('cimg',{attrs:{"btn-class":"-mt-2","fit-img":"","max-h":"300","max-size":"1","max-w":"750","no-info":"","show-text":"","type":"pushNotification"}}),_c('p',{staticClass:"text-info"},[_vm._v("1MB 이하 이미지, 사이즈: 750*300")])],1)]),_vm._l((_vm.urlOpts),function(v){return _c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v(_vm._s(v.title)+" 랜딩 URL")]),_c('div',{staticClass:"w-2/3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ths[v.key]),expression:"ths[v.key]"}],staticClass:"form-control -mt-1.5",attrs:{"placeholder":"https://URL","type":"url"},domProps:{"value":(_vm.ths[v.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ths, v.key, $event.target.value)}}}),_c('p',{staticClass:"text-info"},[_vm._v(_vm._s(v.exp))])])])}),_c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v("더 알아보기(선택)")]),_c('div',{staticClass:"w-2/3 -mt-3"},[_c('addItem',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var data = ref.data;
return [_c('div',{staticClass:"flex w-10/12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.v.title),expression:"data.v.title"}],staticClass:"form-control w-2/3 mr-2",attrs:{"placeholder":"링크 제목","type":"text"},domProps:{"value":(data.v.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.v, "title", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.v.url),expression:"data.v.url"}],staticClass:"form-control w-2/3 ml-2",attrs:{"placeholder":"https://URL","type":"url"},domProps:{"value":(data.v.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.v, "url", $event.target.value)}}})])]}}])}),_c('p',{staticClass:"text-info"},[_vm._v(" 알림함 페이지 내 ‘더 알아보기’ 영역에 노출할 링크입니다. (미입력 시 더 알아보기 영역 없음) ")])],1)]),_c('div',{staticClass:"flex mt-4"},[_c('p',{staticClass:"w-1/10"},[_vm._v("발송대상")]),_c('div',{staticClass:"w-2/3"},[_c('div',{staticClass:"flex mb-4"},_vm._l((['아이디 직접 입력', '대상 선택']),function(v,i){return _c('b-form-radio',{staticClass:"mr-6",attrs:{"name":"selectTarget","value":i},model:{value:(_vm.selectTarget),callback:function ($$v) {_vm.selectTarget=$$v},expression:"selectTarget"}},[_vm._v(" "+_vm._s(v)+" ")])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectTarget),expression:"!selectTarget"}]},[_c('b-form-file',{attrs:{"placeholder":"파일을 선택해주세요.","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","browse-text":"파일 찾기"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('clipboard',{attrs:{"pr":_vm.ths,"defError":"발송대상을 입력해주세요."}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectTarget),expression:"selectTarget"}]},[_c('div',{staticClass:"flex pl-42"},_vm._l(([
								{ title: '핫티스트', val: 'H' },
								{ title: '일반 회원', val: 'M' },
								{ title: '전체 회원', val: 'A' } ]),function(v){return _c('b-form-radio',{staticClass:"mr-6",attrs:{"name":"target","value":v.val},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}},[_vm._v(" "+_vm._s(v.title)+" ")])}),1)]),_c('p',{staticClass:"text-info"},[_vm._v("수신 거부한 회원에게 발송되지 않습니다. (정보성 앱푸시 종류 제외)")])])]),_c('b-button',{staticClass:"block mt-4 px-10 mx-auto",on:{"click":_vm.send}},[_vm._v("등록")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }