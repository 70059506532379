<template>
	<div>
		<bCard>
			<div class="flex">
				<p class="w-1/10">앱푸시 종류</p>
				<div>
					<div class="flex mb-2">
						<b-form-radio
							v-for="v in typeOpts"
							class="mr-6"
							v-model="type"
							name="type"
							@change="changeType"
							:value="v.val"
						>
							{{ v.title }}
						</b-form-radio>
					</div>
					<p v-for="v in typeOpts" class="text-info">{{ v.title }} : {{ v.exp }}</p>
				</div>
			</div>
			<div
				v-for="v in [
					{ title: '템플릿 이름', key: 'name' },
					{ title: '제목', key: 'title' },
				]"
				class="flex mt-4"
			>
				<p class="w-1/10">{{ v.title }}</p>
				<input
					class="form-control w-2/3 -mt-1.5"
					v-model="ths[v.key]"
					:placeholder="v.title + ' 입력'"
					type="text"
				/>
			</div>
			<div class="flex mt-4">
				<p class="w-1/10">내용</p>
				<b-form-textarea class="w-2/3 resize-none" v-model="content" placeholder="내용 입력" rows="3" />
			</div>
			<div class="flex mt-4">
				<p class="w-1/10">이미지(선택)</p>
				<div class="w-2/3">
					<cimg
						btn-class="-mt-2"
						fit-img
						max-h="300"
						max-size="1"
						max-w="750"
						no-info
						show-text
						type="pushNotification"
					/>
					<p class="text-info">1MB 이하 이미지, 사이즈: 750*300</p>
				</div>
			</div>
			<div v-for="v in urlOpts" class="flex mt-4">
				<p class="w-1/10">{{ v.title }} 랜딩 URL</p>
				<div class="w-2/3">
					<input class="form-control -mt-1.5" v-model="ths[v.key]" placeholder="https://URL" type="url" />
					<p class="text-info">{{ v.exp }}</p>
				</div>
			</div>
			<div class="flex mt-4">
				<p class="w-1/10">더 알아보기(선택)</p>
				<div class="w-2/3 -mt-3">
					<addItem>
						<template #default="{ data }">
							<div class="flex w-10/12">
								<input
									class="form-control w-2/3 mr-2"
									v-model="data.v.title"
									placeholder="링크 제목"
									type="text"
								/>
								<input
									class="form-control w-2/3 ml-2"
									v-model="data.v.url"
									placeholder="https://URL"
									type="url"
								/>
							</div>
						</template>
					</addItem>
					<p class="text-info">
						알림함 페이지 내 ‘더 알아보기’ 영역에 노출할 링크입니다. (미입력 시 더 알아보기 영역 없음)
					</p>
				</div>
			</div>
			<div class="flex mt-4">
				<p class="w-1/10">발송대상</p>
				<div class="w-2/3">
					<div class="flex mb-4">
						<b-form-radio
							v-for="(v, i) in ['아이디 직접 입력', '대상 선택']"
							class="mr-6"
							v-model="selectTarget"
							name="selectTarget"
							:value="i"
						>
							{{ v }}
						</b-form-radio>
					</div>
					<div v-show="!selectTarget">
						<b-form-file
							v-model="file"
							placeholder="파일을 선택해주세요."
							accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							browse-text="파일 찾기"
						/>
						<clipboard :pr="ths" defError="발송대상을 입력해주세요." />
					</div>
					<div v-show="selectTarget">
						<div class="flex pl-42">
							<b-form-radio
								v-for="v in [
									{ title: '핫티스트', val: 'H' },
									{ title: '일반 회원', val: 'M' },
									{ title: '전체 회원', val: 'A' },
								]"
								class="mr-6"
								v-model="target"
								name="target"
								:value="v.val"
							>
								{{ v.title }}
							</b-form-radio>
						</div>
					</div>
					<p class="text-info">수신 거부한 회원에게 발송되지 않습니다. (정보성 앱푸시 종류 제외)</p>
				</div>
			</div>

			<b-button class="block mt-4 px-10 mx-auto" @click="send">등록</b-button>
		</bCard>
	</div>
</template>

<script>
import cimg from 'comp/local/cimg'
import addItem from 'comp/local/addItem'
import clipboard from 'comp/local/clipboard'
import { uploadFile } from 'libs/axios'

export default {
	components: { cimg, addItem, clipboard },
	data() {
		const roleGroupKeyOpts = [
				{ label: '전체', value: '' },
				{ label: '일반', value: rule.member },
				{ label: '핫티스트 담당', value: rule.manager },
				{ label: '정산', value: rule.accounting },
				{ label: '어드민', value: rule.admin },
			],
			statusKeyOpts = [
				{ label: '전체', value: '' },
				{ label: '승인', value: 'ACTIVE' },
				{ label: '승인대기', value: 'WAIT' },
				{ label: '비활성', value: 'DEACTIVE' },
			],
			defOpt = {
				roleGroup: roleGroupKeyOpts[0],
				status: statusKeyOpts[0],
			}

		return {
			typeOpts: [
				{ title: '광고성', exp: '메시지 앞 (광고) 포함, 메시지 하단 수신거부 방법 포함 필수', val: 1 },
				{
					title: '판매활동',
					exp: '핫티스트 대상 판매활동 안내, 광고성 미포함, 메시지 하단 수신거부 방법 포함 필수',
					val: 0,
				},
				{ title: '정보성', exp: '앱 서비스 관련 필수 공지사항, 광고성 미포함', val: 2 },
			],
			urlOpts: [
				{
					title: '앱푸시',
					key: 'url',
					exp: '앱푸시를 눌렀을 때 앱에서 랜딩할 페이지입니다. (미입력 시 hott.kr 으로 랜딩)',
				},
				{
					title: '알림함',
					key: 'inboxUrl',
					exp: '알림함 페이지에서 알림을 눌렀을 때 랜딩할 페이지입니다. (미입력 시 hott.kr 으로 랜딩)',
				},
			],
			addItemOpt: { title: '', url: '' },

			type: 1,
			name: '',
			title: '',
			content: '',
			defContent: '',
			url: '',
			inboxUrl: '',
			selectTarget: 0,
			target: 'H',

			file: null,
		}
	},
	methods: {
		changeType() {
			switch (this.type) {
				case 0:
					this.content = '\n*수신거부: 마이핫트>알림설정'
					break
				case 1:
					this.content = '(광고)\n*수신거부: 마이핫트>알림설정\n'
					break
				default:
					//2
					this.content = '\n*본 메시지는 관련 법령에 의거하여 수신동의 여부와 관계없이 발송됩니다'
			}
			this.defContent = this.content.trim()
		},
		send() {
			//클릭한 시각이 오후 8시 50분~오전 8시일 경우
			//분으로 환산 시 480 ~ 1250 사이일 때만 보낼 수 있음
			const e = [],
				d = new Date(),
				m = d.getHours() * 60 + d.getMinutes()
			if (m < 480 || m > 1250) e.push('앱푸시를 발송 가능한 시간이 아닙니다.')

			const data = {
				contentType: this.type,
				type: 1,
			}

			//필수항목 검사
			;[
				{ title: '템플릿 이름', key: 'name' },
				{ title: '제목', key: 'title' },
			].map(v => {
				if (this[v.key]) data[v.key] = this[v.key].trim()
				else e.push(v.title + '을 입력해주세요.')
			})
			if (this.defContent == this.content.trim()) e.push('내용을 입력해주세요.')
			else data.content = this.content.trim()

			this.urlOpts.map(v => {
				if (this[v.key]) {
					if (isUrl(this[v.key])) data[v.key] = this[v.key]
					else e.push(`${v.title} 랜딩 URL에 올바른 url을 입력해주세요`)
				}
			})

			const additionalData = this.addItem.getItem()
			let addItemE = false
			additionalData.map(v => {
				Object.keys(this.addItemOpt).map(k => {
					if (!v[k]) addItemE = true
				})
			})
			if (addItemE) e.push('더 알아보기에 링크제목과 URL을 모두 입력해주세요.')
			else if (additionalData.length) data.additionalData = JSON.stringify({ buttons: additionalData })
			//여기까지 + 이미지 추가가 템플릿 생성 데이터

			//여기부터는 푸시 발송 데이터
			const chooseId = this.selectTarget,
				pushData = { chooseId }
			if (chooseId) pushData.target = this.target
			else {
				if (this.file) pushData.file = this.file
				else {
					if (this.clipboard.e) e.push(this.clipboard.e)
					else pushData.data = this.clipboard.getData
				}
			}

			if (e.length) alert.w(e)
			else {
				confirm('앱푸시를 발송하시겠습니까?', () => {
					//이미지 있으면 업로드하고 저장 시작
					if (!this.cimg.data) this.saveNSend(data, pushData)
					else
						this.cimg.upload().then(imageUrl => {
							data.imageUrl = imageUrl
							this.saveNSend(data, pushData)
						})
				})
			}
		},
		saveNSend(data, pushData) {
			postApi('api/backoffice/pushNotificationHandle/insertTemplate', data).then(({ template }) => {
				pushData.template = template

				let api
				if (pushData.file) {
					const file = pushData.file
					delete pushData.file
					api = uploadFile(
						file,
						'',
						'api/backoffice/pushNotificationHandle/sendPushNotificationByFile',
						undefined,
						pushData
					)
				} else api = postApi('api/backoffice/pushNotificationHandle/sendPushNotification', pushData)

				api.then(() => {
					alert.s('앱푸시 발송을 시작했습니다.\r\n발송량에 따라 시간이 오래 소요될 수 있습니다.')
				})
			})
		},
	},
	created() {
		this.changeType()
		setTimeout(() => {
			this.addItem.init(this.addItemOpt)
		})
	},
}
</script>
